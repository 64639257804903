<template>
    <div id="click" v-if="clinic">
        <div v-if="calling"
             ref="call"
             :class="['call animated fadeInDownBig d-flex align-items-center justify-content-center vw-100 vh-100', 'bg-'+bgTurn(called) ]"
        >
            <div class="text-center">
                <h1>{{ called.type.name }}</h1>
                <p class="big-3">LLAMANDO TURNO</p>
                <h3 class="numbers-1">{{ called.consecutive }}</h3>
                <p class="big-3">{{ called.office.name }}</p>
            </div>
        </div>
        <div class="container-fluid">
            <h1 class="text-center mt-3">
                Bienvenidos a FRYAM {{ clinic.name }}
            </h1>
            <b-card-group deck class="mt-3 mb-3">
                <b-card
                    v-for="office in offices"
                    no-body
                    :bg-variant="bgTurn(office.turn)"
                    text-variant="white"
                    header-class="h1"
                    :header="office.name"
                    class="text-center animated"
                    :class="{'infinite pulse faster': office.turn ? checkTurn(office.turn, 'calling') : false}"
                    :key="office.id"
                >
                    <b-card-text>
                        <span :class="'numbers-'+(office.turn ? String(office.turn.consecutive).length : '1')">{{ office.turn ? office.turn.consecutive : '-' }}</span>
                    </b-card-text>
                    <b-card-footer>
                        {{ office.turn ? office.turn.type.name : '-' }}
                        <span v-if="office.turn ? checkTurn(office.turn, 'attending') : false">
                            {{ office.timer.time }}
                        </span>
                    </b-card-footer>
                    <b-card-footer>
                        <span v-if="office.turn">
                            <marquee-text v-if="checkTurn(office.turn, 'attending')" :repeat="5" :duration="10">
                                <span class="trans">------</span>Atendiendo<span class="trans">------</span>
                            </marquee-text>
                            <span v-else-if="checkTurn(office.turn, 'calling')">
                                Llamando
                            </span>
                            <span v-else-if="checkTurn(office.turn, 'stalled')">
                                Saltado
                            </span>
                            <span v-else-if="checkTurn(office.turn, 'ended')">
                                Atendido
                            </span>
                        </span>
                        <span v-else>
                            -
                        </span>
                    </b-card-footer>
                </b-card>
            </b-card-group>
            <h3 class="text-center">
                {{ new Date() | moment("dddd, MMMM DD YYYY") }} -
                <digital-clock :blink="true" :twelveHour="true" />
            </h3>
        </div>
    </div>

</template>

<script>
import axios from 'axios'

import Echo from "laravel-echo"

import _ from 'lodash'

const url = 'https://new.api.fryam.net/'


const api = axios.create({
    baseURL: `${url}v1/`
})

export default {
    data(){
        return {
            timers: [],
            time: '00:00:00',
            timeBegan : null,
            timeStopped : null,
            stoppedDuration : 0,
            started : null,
            running : false,

            type: 'warning',
            calling: false,
            called: {},
            offices: [],
            clinic: null
        }
    },
    mounted() {
        api.get('dashboard/token/' + this.$route.params.id).then(({data}) => {
            api.defaults.headers.common.Authorization = `Bearer ${data.token}`

            api.get('clinic/' + data.clinic_id).then(({ data }) => {
              this.clinic = data
            })

            api.get('turn/currents/' + data.clinic_id).then(({ data }) => {
              this.offices = data
              this.processTimers()
            })

            window.Echo = new Echo({
                broadcaster: 'pusher',
                authEndpoint: url + 'broadcasting/auth',
                wsHost: 'ws.fryam.net',
                wsPort: 6001,
                key: '6bb5c9e6dc911bd885d7',
                forceTLS: false,
                disableStats: true,
                auth: {
                  headers: {
                    Authorization: 'Bearer ' + data.token
                  }
                }
              })

            window.Echo.private('turn.'+data.clinic_id)
                .listen('ChangeTurn', (e) => {
                    let where = _.findKey(this.offices, (ee) => ee.id == e.turn.office.id )
                    let office = this.offices[where]
                    this.reset(office)
                    office.turn = e.turn
                    this.called = e.turn
                    this.callNotification()
                })
                .listen('AttendedTurn', (e) => {
                    let where = _.findKey(this.offices, (ee) => ee.id == e.turn.office.id )
                    let office = this.offices[where]
                    this.reset(office)
                    office.turn = e.turn
                    this.startTurn(office)
                })
                .listen('StalledTurn', (e) => {
                    let where = _.findKey(this.offices, (ee) => ee.id == e.turn.office.id )
                    let office = this.offices[where]
                    this.reset(office)
                    office.turn = e.turn
                })
                .listen('EndedTurn', (e) => {
                    let where = _.findKey(this.offices, (ee) => ee.id == e.turn.office.id )
                    let office = this.offices[where]
                    this.reset(office)
                    office.turn = e.turn
                })
            ;
        })
    },
    methods: {
        processTimers() {
            _.each(this.offices, (office) => {
                if(office.turn) {
                    if(this.checkTurn(office.turn, 'attending')) {
                        let timer = {
                            id: office.id,
                            time: '00:00:00',
                            timeBegan : null,
                            timeStopped : null,
                            stoppedDuration : 0,
                            started : null,
                            running : false,
                        }

                        if(timer.running) return;

                        if (timer.timeBegan === null) {
                            this.reset(timer);
                            timer.timeBegan = window.moment(office.turn.attended_at);
                        }

                        if (timer.timeStopped !== null) {
                            timer.stoppedDuration += (new Date() - timer.timeStopped);
                        }
                        let that = this
                        timer.started = setInterval(() => {
                            var currentTime = new Date()
                                , timeElapsed = new Date(currentTime - timer.timeBegan - timer.stoppedDuration)
                                , hour = timeElapsed.getUTCHours()
                                , min = timeElapsed.getUTCMinutes()
                                , sec = timeElapsed.getUTCSeconds()

                            timer.time =
                                this.zeroPrefix(hour, 2) + ":" +
                                this.zeroPrefix(min, 2) + ":" +
                                this.zeroPrefix(sec, 2)

                            that.$forceUpdate()
                        }, 1000);

                        timer.running = true;

                        office.timer = timer
                    }
                }
            })
        },
        checkTurn(turn, state) {
            if(state == 'attending') {
                return turn.office_id != null && turn.attended_at != null && turn.attended_by != null && turn.ended_at == null
            } else if (state == 'calling') {
                return turn.office_id != null && turn.attended_at == null && turn.attended_by == null && turn.ended_at == null
            } else if (state == 'stalled') {
                return turn.office_id != null && turn.attended_at != null && turn.attended_by == null && turn.ended_at == null
            } else if (state == 'ended') {
                return turn.office_id != null && turn.attended_at != null && turn.attended_by != null && turn.ended_at != null
            }
        },
        /* eslint-disable */
        bgTurn(turn) {
          if(!turn) {
              return 'secondary'
          } else {
              switch(turn.type.id) {
                  case 1 :
                      return 'info'
                  break
                  case 2 :
                      return 'success'
                  break
                  case 3 :
                      return 'warning'
                  break;
                  case 4 :
                      return 'primary'
                  break
                  case 5 :
                    return 'danger'
                  break
              }
          }
        },
        /* eslint-enable */
        startTurn(office) {
            let timer = {
                id: office.id,
                time: '00:00:00',
                timeBegan : null,
                timeStopped : null,
                stoppedDuration : 0,
                started : null,
                running : false,
            }

            if(timer.running) return;

            if (timer.timeBegan === null) {
                this.reset(timer);
                timer.timeBegan = new Date();
            }

            if (timer.timeStopped !== null) {
                timer.stoppedDuration += (new Date() - timer.timeStopped);
            }
            let that = this
            timer.started = setInterval(() => {
                var currentTime = new Date()
                    , timeElapsed = new Date(currentTime - timer.timeBegan - timer.stoppedDuration)
                    , hour = timeElapsed.getUTCHours()
                    , min = timeElapsed.getUTCMinutes()
                    , sec = timeElapsed.getUTCSeconds()

                timer.time =
                    this.zeroPrefix(hour, 2) + ":" +
                    this.zeroPrefix(min, 2) + ":" +
                    this.zeroPrefix(sec, 2)

                that.$forceUpdate()
            }, 1000);

            timer.running = true;

            office.timer = timer
        },
        reset(timer) {
            timer.running = false;
            clearInterval(timer.started);
            timer.stoppedDuration = 0;
            timer.timeBegan = null;
            timer.timeStopped = null;
            timer.time = "00:00:00";
        },


        clockRunning(timer){
            console.log(timer)
            // var currentTime = new Date()
            //     , timeElapsed = new Date(currentTime - this.timeBegan - this.stoppedDuration)
            //     , hour = timeElapsed.getUTCHours()
            //     , min = timeElapsed.getUTCMinutes()
            //     , sec = timeElapsed.getUTCSeconds()
            //
            // this.time =
            //     this.zeroPrefix(hour, 2) + ":" +
            //     this.zeroPrefix(min, 2) + ":" +
            //     this.zeroPrefix(sec, 2)
        },
        zeroPrefix(num, digit) {
            var zero = '';
            for(var i = 0; i < digit; i++) {
                zero += '0';
            }
            return (zero + num).slice(-digit);
        },

        callNotification() {
            this.calling = true
            var audio = new Audio('/eventually.mp3')
            audio.play()
            setTimeout(() => this.$refs.call.classList.add('fadeOutUpBig'), 8000)
            setTimeout(() => {this.calling = false; this.$refs.call.classList.remove('fadeOutUpBig')}, 9000)
        }
    }
}
</script>

<style lang="scss">
.trans {color:transparent !important;}
.call {
    position:absolute;
    z-index:100000;
    top:0;
}
body {
    background:#fff;
    font-size: 1.5em;
}
.big-1{font-size: 13em;}
.big-3{font-size: 5.2em;line-height: .9em;}
.numbers {
    font-weight: bold;
    &-1 {
        @extend .numbers;
        font-size: 13em;
        line-height: .8em;
    }
    &-2 {
        @extend .numbers;
        font-size: 13em;
        line-height: .8em;
    }
    &-3 {
        @extend .numbers;
        font-size: 9.2em;
        line-height: 1.14em;
    }
}
</style>
